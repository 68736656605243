/* !Root Start */
:root {
    --primary: linear-gradient(0deg, rgb(69, 41, 29) 0%, rgba(0, 0, 0, 1) 100%);
    --secondary: #C4A484;
    --transition: 0.4s 0.2s ease-in-out;
    --hover: #4c7c58;
    --white: white;
    --black: black;
    --whiteOpacity: rgba(255, 255, 255, 0.7);
    --box-shadow: 0 0 8px rgb(167, 164, 164);
    --box-shadow-white: 0 0 4px rgb(255, 255, 255);
    --box-shadow-green: 0 0 10px #C4A484;
    --bred: 2px solid red;
    --bgreen: 2px solid green;
    --bblue: 2px solid blue;
    --borange: 2px solid orange;
}

* {
    box-sizing: border-box;
}

:focus {
    outline: 0;
}

html {
    font-size: 62.5%;
    position: relative;
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background: var(--bg);
    font-size: 1.4rem;
    color: var(--black);
    font-family: "Jameel Noori Nastaleeq";
    background: linear-gradient(rgba(255, 255, 255, 0.86), rgba(255, 255, 255, 0.86)), url("../Images/image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

[dir="rtl"] body {
    font-size: 1.8rem;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

a:hover {
    cursor: pointer;
    transition: var(--transition);
}

hr {
    width: 100%;
    margin: 0;
}

.rtl-layout {
    direction: rtl;
}

.dropdown-toggle::after {
    display: none !important;
}

.dropdown-menu.show {
    border: none;
    text-align: right;
    font-size: 1.6rem;
    box-shadow: var(--box-shadow);
}

.scrolled {
    background: var(--whiteOpacity);
    transition: var(--transition);
    box-shadow: var(--box-shadow);
    backdrop-filter: blur(5px);
}

.text-outline {
    display: inline-block;
}

.text-outline h1 {
    color: #fff;
    -webkit-text-stroke: 2px var(--primary);
    text-stroke: 2px var(--primary);
}

.active-link {
    color: var(--secondary) !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
    color: var(--black);
}

/* !Root End */

/* !Navbar Start */
.navbar {
    padding: 0 !important;
}

.navbar-brand {
    width: 6rem;
}

.navbar-expand-lg .navbar-nav .nav-link {
    font-size: 1.6rem;
    color: var(--primary);
    font-weight: 600;
    margin: 1rem;
}

.navbar-expand-lg .navbar-nav .nav-link.active {
    color: var(--secondary);
}

.navbar-expand-lg .navbar-nav .nav-link.show {
    color: var(--primary);
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
    color: var(--secondary);
}

.navbar-expand-lg .navbar-nav .sign-up {
    background: var(--primary);
    color: var(--white);
    display: block;
    padding: 0.2rem;
    border-radius: 2rem;
    width: 8rem;
    text-align: center;
    margin-right: 1rem;
    border: 1px solid var(--primary);
}

.navbar-expand-lg .navbar-nav .sign-up:hover {
    background: var(--secondary);
    color: var(--hover);
    border: 1px solid var(--primary);
    transition: var(--transition);
}

.search .input-group {
    border: 2px solid var(--primary) !important;
    border-radius: 2rem !important;
}

.search .input-group-text {
    background: var(--white) !important;
    border: 1px solid transparent !important;
    border-radius: 0 2rem 2rem 0 !important;
}

.search .form-control {
    font-size: 1.8rem !important;
    border: 1px solid transparent !important;
    border-radius: 2rem 0 0 2rem !important;
}

.search .form-control:focus {
    box-shadow: none !important;
    border: 1px solid transparent !important;
}

.search .suggestions {
    width: 100%;
    max-height: 14rem;
    position: absolute;
    background: var(--white);
    border-radius: 0.4rem;
    z-index: 999;
    overflow: scroll;
    direction: ltr;
    box-shadow: var(--box-shadow);
    margin-top: 4rem;
}

.search .suggestions::-webkit-scrollbar {
    width: 0.4rem;
    background: transparent;
}

.search .suggestions::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 1rem;
}

.search .suggestions ul {
    list-style-type: none;
    direction: rtl;
}

.search .suggestions ul li:hover {
    cursor: pointer;
    color: var(--secondary);
    transition: var(--transition);
}

.modal {
    background: var(--whiteOpacity);
    backdrop-filter: blur(4px);
}

.modal-header {
    border: 2px solid var(--white) !important;
}

.modal-header .btn-close {
    margin: 0 !important;
}

.modal-title {
    display: block !important;
    width: 100% !important;
    text-align: center !important;
    font-size: 4rem !important;
    color: var(--primary) !important;
    font-weight: 600 !important;
}

.modal-body {
    padding: 2rem !important;
}

.modal-body .form-group {
    position: relative;
}

.modal-body .form-group input {
    width: 100%;
    margin: 2rem auto;
    border: none;
    border-bottom: 1px solid #ced4da !important;
    border-radius: 0 !important;
    font-size: 1.6rem;
    transition: border-color 0.15s ease-in-out;
}

[dir="rtl"] .modal-body .form-group input {
    direction: rtl;
}

.modal-body .form-group input:focus {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ced4da;
}

.modal-body .form-group input:focus + .floating-label,
.modal-body .form-group input:not(:placeholder-shown) + .floating-label {
    top: -1.2rem;
    left: 0.6rem;
    font-size: 1.2rem;
    opacity: 1;
    color: var(--primary);
}

.modal-body .form-group .floating-label {
    position: absolute;
    top: 0.4rem;
    left: 0.8rem;
    color: var(--placeholder);
    pointer-events: none;
    font-size: 1.8rem;
    transition: var(--transition);
}

[dir="rtl"] .modal-body .form-group .floating-label {
    right: 0.8rem;
    text-align: right;
}

.PhoneInput {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

[dir="rtl"] .PhoneInput {
    direction: ltr;
}

.btn-modal {
    width: 100% !important;
    font-size: 2.2rem !important;
    font-weight: 700 !important;
    border: 1px solid var(--primary) !important;
    background: var(--secondary) !important;
    color: var(--primary) !important;
    margin: 4rem auto 4rem auto !important;
}

.btn-modal:hover {
    border: 1px solid var(--primary) !important;
    background: var(--primary) !important;
    color: var(--white) !important;
    transition: var(--transition);
}

.video-model .modal-dialog {
    max-width: 80%;
}

.video-model .modal-dialog .modal-content {
    height: 56rem;
}

/* !Navbar End */
/* !Footer Start */
.footer {
    height: 6rem;
    background: var(--white);
    padding: 0 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--box-shadow);
}

.footer .logo {
    width: 4rem;
    margin: 1rem;
}

/* !Footer End */
/* !Home Start */
.home-swiper-parent {
    margin-top: 7rem;
    margin-bottom: 4rem;
    text-align: center;
    height: 53rem;
}

.home-swiper {
    height: 53rem;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    color: var(--primary);
    background: var(--whiteOpacity);
    border-radius: 50%;
    padding: 0.55rem 1rem;
    display: none;
    backdrop-filter: blur(5px);
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 2rem;
    width: 4rem !important;
    height: 3rem !important;
}

.home-swiper:hover .swiper-button-prev:after,
.home-swiper:hover .swiper-button-next:after {
    display: block;
    cursor: pointer;
    transition: var(--transition);
}

.home-swiper img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.stanzas {
    display: flex;
    flex-wrap: wrap;
}

.stanzas .stanza-swiper {
    height: 20rem;
}

.stanza-swiper:hover .swiper-button-prev:after,
.stanza-swiper:hover .swiper-button-next:after {
    display: block;
    cursor: pointer;
    transition: var(--transition);
}

.stanzas .stanza {
    width: 90%;
    margin: 1.4rem;
    text-align: center;
    box-shadow: var(--box-shadow);
    padding: 1rem;
    position: relative;
    font-size: 2.2rem;
    line-height: 3.2rem;
    background-color: white;
}

.stanzas .stanza svg {
    position: absolute;
    bottom: 4%;
    right: 4%;
}

.content {
    margin: 4rem auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.content .content-card {
    border: none;
    text-align: center;
    width: 33%;
    margin: 2rem auto;
}

.content .content-card:hover {
    cursor: pointer;
    color: var(--secondary);
    transition: var(--transition);
}

.content .content-card h1 {
    font-size: 3rem;
}


.content .content-card .card-img {
    width: 100%;
}

.content .content-card .card-img svg {
    width: 30%;
}

/* !Home End */

/* !Books Start */
.books-filter {
    margin: 12rem 2rem 2rem 2rem;
    height: 4.4rem;
    display: flow-root;
    margin-right: 2rem;
}

.books-filter .book-type {
    display: flex;
    font-weight: 700;
    float: right;
}

.books-filter .book-lang {
    display: flex;
    font-weight: 700;
    float: left;
}

.books-filter .nav-tabs {
    padding: 0;
}

.books-filter .nav-tabs .nav-link {
    background: var(--white);
    color: var(--primary);
    border-radius: 0.4rem;
    width: 6rem;
    padding: 0.4rem;
}

.books-filter .nav-tabs .nav-link.active {
    background: var(--primary);
    color: var(--white) !important;
}

.books {
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
}

.books .book {
    width: 20rem;
    margin: 4rem;
    box-shadow: var(--box-shadow);
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 30px solid transparent;
    border-image: url('../Images/border.jpg');
    border-image-slice: 22%;
    border-radius: 10px 0 10px 0;
    background-color: white;
}

.books .book:hover {
    cursor: pointer;
    transition: var(--transition);
    color: var(--primary);
    box-shadow: var(--box-shadow-green);
}

.books .book .book-img {
    width: 100%;
    height: 24rem;
    overflow: hidden;
}

.books .book .book-img img {
    object-fit: cover;
    height: 26rem;
}

.books .book h1 {
    font-size: 3.6rem;
}

/* !Books End */

/* !Book Details Start */
.book-details {
    position: relative;
    height: 100vh;
}

.book-details .sidebar {
    background: var(--primary);
    padding-right: 2rem;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    direction: ltr;
}

.book-details .sidebar::-webkit-scrollbar {
    width: 1.4rem;
    background: var(--secondary);
}

.book-details .sidebar::-webkit-scrollbar-thumb {
    background: var(--primary);
}

.book-details .active-poem h3 {
    color: var(--secondary) !important;
}

.book-details .sidebar h1 {
    color: var(--white);
    font-size: 3.4rem;
    position: sticky;
    top: 0;
    background: var(--black);
    padding: 8rem 4rem 1rem 1rem;
    direction: rtl;
    width: 30.5rem;
}

.book-details .sidebar h1 img {
    padding-left: 1rem;
}

.book-details .sidebar h1 img:hover {
    cursor: pointer;
    transition: var(--transition);
    color: var(--secondary);
}

.book-details .sidebar h3 {
    color: var(--white);
    margin: 3rem 0 3rem 0.2rem;
    direction: rtl;
    line-height: 3rem;
}

.book-details .sidebar h3:hover {
    cursor: pointer;
    transition: var(--transition);
    color: var(--secondary);
}

.book-details .sidebar h3 .options {
    float: left;
}

.book-details .sidebar h3 .options svg {
    margin: auto 0.4rem;
}

.book-details .sidebar h3 .options svg:hover {
    fill: var(--hover);
    transition: var(--transition);
}

.book-details .sidebar h3 span {
    margin-left: 2rem;
}

.book-details .sidebar h3:hover {
    color: var(--secondary);
    cursor: pointer;
    transition: var(--transition);
}

.book-details .main-bar {
    padding-top: 8rem;
    padding-right: 2rem;
    text-align: center;
    padding-bottom: 4rem;
}

.book-details .main-bar .poem {
    height: 86vh;
    overflow-y: scroll;
    padding-bottom: 1rem;
    direction: ltr;
}

.book-details .main-bar .poem::-webkit-scrollbar {
    width: 1rem;
}

.book-details .main-bar .poem::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 5rem;
}

.book-details .main-bar .poem h1 {
    font-size: 3.5rem;
    position: sticky;
    top: 0;
    padding: 1rem;
    background: var(--white);
}

.book-details .main-bar .poem span {
    font-size: 2.5rem;
    line-height: 6rem;
}

.book-details .main-bar .meanings {
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
    position: sticky;
    background: var(--primary);
    margin-left: 2rem;
    border-radius: 1rem;
    box-shadow: var(--box-shadow);
    color: var(--white);
    direction: ltr;
}

.book-details .main-bar .meanings::-webkit-scrollbar {
    background: var(--primary);
    width: 1rem;
    border-radius: 2rem;
}

.book-details .main-bar .meanings::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 2rem;
}

.book-details .main-bar .meanings::-moz-scrollbar {
    background: var(--primary);
    border-radius: 2rem;
    width: 1rem;
}

.book-details .main-bar .meanings::-moz-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 2rem;
}

.book-details .main-bar .meanings .meaning-tab {
    position: sticky;
    top: 0;
    background: var(--black);
    color: var(--white);
    display: flex;
    padding: 0;
    justify-content: space-evenly;
    margin-left: 1rem;
}

.book-details .main-bar .meanings .nav-link {
    color: var(--white);
}

.book-details .main-bar .meanings .nav-link.active {
    color: var(--primary);
}

.book-details .main-bar .meanings ul.ul-meaning li {
    list-style-type: none;
    margin-bottom: 4rem;
}

.book-details .main-bar .meanings .meaning {
    padding: 1rem;
    text-align: right;
    direction: rtl;
}

.book-details .main-bar .meanings .meaning span {
    font-size: 2rem;
}

.book-details .main-bar .meanings .translation {
    padding: 1rem;
    text-align: right;
    direction: rtl;
}

.book-details .main-bar .meanings .translation span {
    font-size: 2rem;
    line-height: 5rem;
}

.book-details .main-bar .meanings .explanation {
    padding: 1rem;
    text-align: right;
    direction: rtl;
    line-height: 5rem;
}

.book-details .main-bar .meanings .explanation span {
    font-size: 2rem;
    line-height: 5rem;
}

/* !Book Details End */

/* !Videos Start */
.video-details {
    padding: 12rem 4rem 2rem 4rem;
    height: 100vh;
    min-height: 100vh;
}

.video-details .filter {
    height: 4.4rem;
    display: flow-root;
    margin-right: 2rem;
}

.video-details .filter .video-type {
    display: flex;
    font-weight: 700;
}

.video-details .filter .nav-tabs {
    padding: 0;
}

.video-details .filter .nav-tabs .nav-link {
    background: var(--white);
    color: var(--primary);
    border-radius: 0.4rem;
    width: 6rem;
    padding: 0.4rem;
}

.video-details .filter .nav-tabs .nav-link.active {
    background: var(--primary);
    color: var(--white) !important;
}

.video-details .videos {
    display: flex;
    flex-wrap: wrap;
}

.video-details .videos .video {
    height: 20.4rem;
    width: 30%;
    margin: 2rem;
}

/* !Videos End */

/* !Audio Start */
.audio-details {
    padding: 12rem 4rem 2rem 4rem;
    min-height: 100vh;
}

.audio-details .filter {
    height: 4.4rem;
    display: flow-root;
    margin-right: 2rem;
}

.audio-details .filter .audio-type {
    display: flex;
    font-weight: 700;
}

.audio-details .filter .nav-tabs {
    padding: 0;
}

.audio-details .filter .nav-tabs .nav-link {
    background: var(--white);
    color: var(--primary);
    border-radius: 0.4rem;
    width: 6rem;
    padding: 0.4rem;
}

.audio-details .filter .nav-tabs .nav-link.active {
    background: var(--primary);
    color: var(--white) !important;
}

.audio-details .audios {
    display: flex;
    flex-wrap: wrap;
}

.audio-details .audios .audio {
    height: 6rem;
    width: 26%;
    margin: 2rem;
}

audio {
    width: 97%;
    margin: 0.4rem;
}


.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--black) !important;
}

/* !Audio End */

/* !Biography Start */
.bio-details {
    position: relative;
    padding: 0;
}

.bio-details .sidebar {
    background: var(--primary);
    padding-right: 2rem;
    padding-top: 6rem;
    height: 100vh;
    overflow-y: scroll;
    direction: ltr;
}

.bio-details .sidebar::-webkit-scrollbar {
    width: 1.4rem;
    background: var(--secondary);
}

.bio-details .sidebar::-webkit-scrollbar-thumb {
    background: var(--primary);
}

.bio-details .active-poem h3 {
    color: var(--secondary) !important;
}

.bio-details .sidebar h3 {
    color: var(--white);
    margin: 3rem 0 3rem 0.2rem;
    direction: rtl;
    font-size: 2rem;
    line-height: 3rem;
}

.bio-details .sidebar h3:hover {
    cursor: pointer;
    transition: var(--transition);
    color: var(--secondary);
}

.bio-details .main-bar {
    padding-top: 8rem;
    padding-right: 2rem;
    padding-bottom: 4rem;
    text-align: center;
}

.bio-details .main-bar .detail {
    height: 80vh;
    overflow-y: scroll;
    padding-bottom: 1rem;
    margin-left: 0.2rem;
}

.bio-details .main-bar .detail::-webkit-scrollbar {
    width: 0.6rem;
}

.bio-details .main-bar .detail::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 5rem;
}

.bio-details .main-bar .detail h1 {
    font-size: 3.5rem;
    position: sticky;
    top: 0;
    padding: 2rem 1rem 1rem 1rem;
    background: var(--white);
}

.bio-details .main-bar .detail span {
    font-size: 2.2rem;
    line-height: 5rem;
}

/* !Biography End */


/* !Kids Start */
.kids .kids-header {
    display: flow-root;
    padding-top: 8rem;
}

.kids .kids-header .right {
    float: right;
    width: 50%;
}

.kids .kids-header .right h1 {
    font-size: 5rem;
    font-weight: 600;
    text-align: center;
    margin: 5rem auto;
    color: var(--primary);
}

.kids .kids-header .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.kids .kids-header .cards .card {
    width: 40%;
    height: 12rem;
    margin: 2rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: var(--box-shadow);
}

.kids .kids-header .cards .card:hover {
    cursor: pointer;
    transition: var(--transition);
    color: var(--primary);
    box-shadow: var(--box-shadow-green);
}

.kids .kids-header .cards .card h2 {
    font-size: 3.6rem;
    font-weight: 600;
    text-align: center;
    margin: 1rem auto;
}

.kids .kids-header .left {
    float: left;
    width: 50%;
    height: 90vh;
}

.kids .kids-header .left .img {
    width: 100%;
    height: 80vh;
    position: relative;
}

.kids .kids-header .left .img .background {
    position: absolute;
    width: 52rem;
    right: 4%;
}

.kids .kids-header .left .img .bottom {
    position: absolute;
    bottom: 0;
}

.kids .kids-header .left .img .leaf {
    position: absolute;
    width: 10rem;
    left: 1%;
    bottom: 1%;
}

.kids .kids-header .left .img .books1 {
    position: absolute;
    width: 16rem;
    left: 10%;
    top: 68.5%;
}

.kids .kids-header .left .img .person1 {
    position: absolute;
    width: 12rem;
    left: 4%;
    bottom: 18%;
}

.kids .kids-header .left .img .glass {
    position: absolute;
    width: 14rem;
    right: 2%;
    bottom: 0;
}

.kids .kids-header .left .img .person2 {
    position: absolute;
    width: 10rem;
    right: 4%;
    bottom: 0;
}

.kids .kids-header .left .img .largeBook {
    position: absolute;
    width: 34rem;
    right: 14%;
    bottom: 10%;
}

.kids .kids-header .left .img .bulb {
    position: absolute;
    width: 20rem;
    right: 6%;
    top: 2%;
}

.kids .kids-header .left .img .smallBook {
    position: absolute;
    width: 16rem;
    left: 12%;
    top: 20%;
}

.kids .kids-header .left .img .outline {
    position: absolute;
    width: 16rem;
    left: 24%;
    top: 12%;
}

/* !Kids End */

/* !Poems Start */
.poem-details {
    position: relative;
    padding: 0;
}

.poem-details .sidebar {
    background: var(--primary);
    padding-right: 2rem;
    height: 100vh;
    overflow-y: scroll;
    direction: ltr;
    padding-top: 8rem;
}

.poem-details .sidebar::-webkit-scrollbar {
    width: 1.4rem;
    background: var(--secondary);
}

.poem-details .sidebar::-webkit-scrollbar-thumb {
    background: var(--primary);
}

.poem-details .active-poem h3 {
    color: var(--secondary) !important;
}

.poem-details .sidebar h3 {
    color: var(--white);
    margin: 3rem 0 3rem 0.2rem;
    direction: rtl;
    line-height: 3rem;
}

.poem-details .sidebar h3:hover {
    cursor: pointer;
    transition: var(--transition);
    color: var(--secondary);
}

.poem-details .sidebar h3 .options {
    float: left;
}

.poem-details .sidebar h3 .options svg {
    margin: auto 0.4rem;
}

.poem-details .sidebar h3 .options svg:hover {
    fill: var(--hover);
    transition: var(--transition);
}

.poem-details .sidebar h3 span {
    margin-left: 2rem;
}

.poem-details .sidebar h3:hover {
    color: var(--secondary);
    cursor: pointer;
    transition: var(--transition);
}

.poem-details .main-bar {
    padding-top: 8rem;
    padding-right: 2rem;
    text-align: center;
}

.poem-details .main-bar .poem {
    height: 86vh;
    overflow-y: scroll;
    padding-bottom: 1rem;
}

.poem-details .main-bar .poem span {
    font-size: 2.5rem;
    line-height: 6rem;
}

.poem-details .main-bar .poem::-webkit-scrollbar {
    width: 1rem;
}

.poem-details .main-bar .poem::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 5rem;
}

.poem-details .main-bar .poem h1 {
    font-size: 3.5rem;
    position: sticky;
    top: 0;
    padding: 2rem 1rem 1rem 1rem;
    background: var(--white);
}

.poem-details .main-bar .meanings {
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
    position: sticky;
    background: var(--primary);
    margin-left: 2rem;
    border-radius: 1rem;
    box-shadow: var(--box-shadow);
    color: var(--white);
}

.poem-details .main-bar .meanings::-webkit-scrollbar {
    background: var(--primary);
    width: 1rem;
    border-radius: 2rem;
}

.poem-details .main-bar .meanings::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 2rem;
}

.poem-details .main-bar .meanings::-moz-scrollbar {
    background: var(--primary);
    border-radius: 2rem;
    width: 1rem;
}

.poem-details .main-bar .meanings::-moz-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 2rem;
}

.poem-details .main-bar .meanings .meaning-tab {
    position: sticky;
    top: 0;
    background: var(--black);
    color: var(--white);
    display: flex;
    padding: 0;
    justify-content: space-evenly;
    margin-right: 1rem;
}

.poem-details .main-bar .meanings .nav-link {
    color: var(--white);
}

.poem-details .main-bar .meanings .nav-link.active {
    color: var(--primary);
}

.poem-details .main-bar .meanings ul.ul-meaning li {
    list-style-type: none;
    margin-bottom: 4rem;
}

.poem-details .main-bar .meanings .meaning {
    padding: 1rem;
    text-align: justify;
}

.poem-details .main-bar .meanings .meaning span {
    font-size: 2rem;
}

.poem-details .main-bar .meanings .translation {
    padding: 1rem;
    text-align: justify;
}

.poem-details .main-bar .meanings .translation span {
    font-size: 2rem;
    line-height: 5rem;
}

.poem-details .main-bar .meanings .explanation {
    padding: 1rem;
    text-align: justify;
    line-height: 5rem;
}

.poem-details .main-bar .meanings .explanation span {
    font-size: 2rem;
    line-height: 5rem;
}

/* !Poems End */

/* !Challenge Start */
.challenge {
    height: 100vh;
    max-height: auto;
}

.challenge .challenge-filter {
    margin: 12rem 2rem 4rem 2rem;
    display: flow-root;
    margin-right: 2rem;
}

.challenge .challenge-filter .challenge-type {
    display: flex;
    font-weight: 700;
    float: right;
}

.challenge .challenge-filter .nav-tabs {
    padding: 0;
    border: 0;
}

.challenge .challenge-filter .nav-tabs .nav-link {
    background: var(--white);
    color: var(--primary);
    border-radius: 0.4rem;
    width: 6rem;
    padding: 0.4rem;
}

.challenge .challenge-filter .nav-tabs .nav-link.active {
    background: var(--primary);
    color: var(--white) !important;
}

.challenge .challenge-stanzas {
    margin: 4rem;
    display: flex;
    flex-wrap: wrap;
}

.challenge .challenge-stanzas .stanza {
    margin: 4rem 2rem;
    padding: 1rem;
    width: 21%;
    height: 10rem;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: var(--box-shadow);
    text-align: center;
    font-size: 2.3rem;
    line-height: 3.6rem;
}

/* !Challenge End */

/* !Quiz Start */
.quiz-details {
    position: relative;
    padding: 0;
}

.quiz-details .sidebar {
    background: var(--primary);
    padding-right: 1rem;
    height: 100vh;
    overflow-y: scroll;
    direction: ltr;
}

.quiz-details .sidebar::-webkit-scrollbar {
    width: 1.4rem;
    background: var(--secondary);
}

.quiz-details .sidebar::-webkit-scrollbar-thumb {
    background: var(--primary);
}

.quiz-details .sidebar button {
    margin-top: 8rem;
    width: 100%;
    font-size: 2.4rem;
    background: var(--secondary);
    box-shadow: none;
    outline: none;
    border: 1px solid var(--white);
}

.quiz-details .sidebar button:hover,
.quiz-details .sidebar button:active {
    transition: var(--transition);
    background: var(--primary);
    box-shadow: none;
    outline: none;
    border: 1px solid var(--white);
}

.quiz-details .active-poem h4 {
    color: var(--secondary) !important;
}

.quiz-details .sidebar h1 {
    color: var(--white);
    font-size: 4rem;
    position: sticky;
    top: 0;
    background: var(--primary);
    padding: 10rem 1rem 1rem 1rem;
    direction: rtl;
}


.quiz-details .sidebar h4 {
    color: var(--white);
    margin: 3rem 0 3rem 0.2rem;
    direction: rtl;
    font-size: 2rem;
    line-height: 3rem;
}

.quiz-details .sidebar h4:hover {
    cursor: pointer;
    transition: var(--transition);
    color: var(--secondary);
}

.quiz-details .sidebar h4 .options {
    float: left;
}

.quiz-details .sidebar h4 .options svg {
    margin: auto 0.4rem;
}

.quiz-details .sidebar h3 span {
    margin-left: 2rem;
    line-height: 3rem;
}

.quiz-details .sidebar h3:hover {
    color: var(--secondary);
    cursor: pointer;
    transition: var(--transition);
}



.quiz-details .main-bar {
    padding-top: 12rem;
    padding-right: 2rem;
    padding-bottom: 4rem;
}

.quiz-details .main-bar .quiz {
    height: 73vh;
    overflow-y: scroll;
    padding-bottom: 1rem;
    line-height: 3.2rem;
}

.quiz-details .main-bar .quiz::-webkit-scrollbar {
    width: 1rem;
}

.quiz-details .main-bar .quiz::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 5rem;
}

.quiz-details .main-bar .quiz .answers {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
}

.quiz-details .main-bar .quiz .answers span {
    width: 45%;
    margin: 1rem;
}

.create-quiz {
    position: relative;
}

.create-quiz .input-group {
    border: 2px solid var(--primary) !important;
    border-radius: 2rem !important;
}

.create-quiz .answer {
    width: 50%;
    float: left;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

.create-quiz .answer label {
    color: var(--primary);
    font-weight: 700;
    margin-right: 1rem;
}

.create-quiz .input-group.number {
    width: 50%;
    float: right;
}

.create-quiz .input-group > .form-control {
    border-radius: 4rem !important;
}

.create-quiz .input-group > .form-floating {
    border-radius: 4rem !important;
}

.create-quiz .input-group > .form-select {
    border-radius: 4rem !important;
}

.create-quiz .input-group-text {
    background: var(--white) !important;
    border: 1px solid transparent !important;
    border-radius: 0 2rem 2rem 0 !important;
}

.create-quiz .form-control {
    font-size: 1.8rem !important;
    border: 1px solid transparent !important;
    border-radius: 2rem 0 0 2rem !important;
    box-shadow: none;
}

.create-quiz .form-control:focus {
    box-shadow: none !important;
    border: 1px solid transparent !important;
}

.create-quiz .form-check-input:checked {
    background: var(--primary);
    border: var(--primary);
    box-shadow: none;
}

.create-quiz .suggestions {
    width: 92%;
    max-height: 12rem;
    position: absolute;
    background: var(--white);
    border-radius: 1rem;
    z-index: 999;
    overflow: scroll;
    direction: ltr;
    box-shadow: var(--box-shadow);
}

.create-quiz .suggestions::-webkit-scrollbar {
    width: 0.4rem;
    background: transparent;
}

.create-quiz .suggestions::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 1rem;
}

.create-quiz .suggestions ul {
    list-style-type: none;
    direction: rtl;
}

.create-quiz .suggestions ul li:hover {
    cursor: pointer;
    color: var(--secondary);
    transition: var(--transition);
}

.create-quiz .quiz-keywords {
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    width: 96%;
}

.create-quiz .quiz-keywords .quiz-keyword {
    margin: 0.6rem;
    padding: 0.4rem;
    width: 6rem;
    text-align: center;
    background: var(--primary);
    border-radius: 2rem;
    color: var(--white);
}

/* !Quiz End */

/* !Gallery Start */
.gallery-header {
    width: 100%;
    height: 60vh;
    position: relative;
    margin-top: 8rem;
}

.gallery-header h1 {
    position: absolute;
    color: white;
    top: 26%;
    left: 36%;
    z-index: 999;
    font-size: 12rem;
}

.gallery-header img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
}

.gallery-header .image-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(69, 41, 29, 0.8), rgba(0, 0, 0, 0.8));
    z-index: 1;
}

.gallery {
    padding: 8rem 0;
    display: flex;
    flex-wrap: wrap;
}

.gallery .gallery-img {
    width: 24%;
    height: 14rem;
    margin: 0.5rem;
}

.gallery .gallery-img:hover {
    cursor: pointer;
}

.gallery .gallery-img img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.react-responsive-modal-modal {
    padding: 0 !important;
    height: 96vh !important;
    width: 94% !important;
    max-width: 100% !important;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4) !important;
}

.react-responsive-modal-modal img {
    object-fit: contain;
    height: 96vh !important;
    width: 100% !important;
}

.react-responsive-modal-root {
    z-index: 1100 !important;
}


.react-responsive-modal-closeButton {
    fill: white !important;
}

.react-responsive-modal-modal .modal-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prev-btn,
.next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 50%;
}

.prev-btn svg,
.next-btn svg {
    margin-top: -5px;
}

.prev-btn {
    left: 1rem;
}

.next-btn {
    right: 1rem;
}

/* !Gallery End */

/* !Profile Start */
.profile {
    position: relative;
    padding: 0;
}

.profile .sidebar {
    background: var(--primary);
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 8rem;
    height: 100vh;
}

.profile .sidebar .profile-img {
    width: 16rem;
    height: 16.4rem;
    border-radius: 50%;
}

.profile .sidebar .profile-img img {
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
    object-fit: cover;
}

.profile .sidebar .profile-details {
    color: var(--white);
    margin: 1rem;
}

.profile .sidebar .profile-details h4 {
    margin: 1rem 0;
}

.profile .sidebar .profile-details h3 {
    color: var(--secondary);
    margin: 3rem 0 3rem 0.2rem;
    direction: rtl;
}

.profile .sidebar .profile-details h3:hover {
    cursor: pointer;
    transition: var(--transition);
    color: var(--white);
}

.profile .sidebar button {
    margin-top: 12rem;
    width: 100%;
    font-size: 2.4rem;
    background: var(--secondary);
    box-shadow: none;
    outline: none;
    border: 1px solid var(--primary);
}

.profile .sidebar button:hover,
.profile .sidebar button:active {
    transition: var(--transition);
    background: var(--primary);
    box-shadow: none;
    outline: none;
    border: 1px solid var(--white);
}

.profile .main-bar {
    padding-top: 6rem;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 4rem;
}

.profile .main-bar .detail {
    padding-bottom: 1rem;
}

.profile .main-bar .detail span:hover {
    color: var(--secondary);
    transition: var(--transition);
    cursor: pointer;
}

.profile .main-bar .detail a {
    font-size: 2.2rem;
    color: var(--black);
    text-decoration: none;
}

.profile .main-bar .detail a:hover {
    color: var(--secondary);
    transition: var(--transition);
}

.profile .main-bar .detail::-webkit-scrollbar {
    width: 1rem;
}

.profile .main-bar .detail::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 5rem;
}

.profile .main-bar .detail h1 {
    color: var(--primary);
    font-size: 4rem;
    margin: 2rem;
    text-align: right;
}

.profile .main-bar .detail .nav-tabs {
    padding: 0;
    border: 0;
}

.profile .main-bar .detail .nav-tabs .nav-link {
    background: var(--white);
    color: var(--primary);
    border-radius: 0.4rem;
    padding: 0.4rem;
}

.profile .main-bar .detail .nav-tabs .nav-link.active {
    background: var(--primary);
    color: var(--white);
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    font-size: 1.4rem;
    margin-top: 2.4rem;
}

.form-control[type=file]:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--primary);
}

.form-label {
    margin-bottom: 0.5rem;
    margin-top: 2rem;
}

/* !Profile End */

/* !Search Start */
.search {
    position: relative;
    padding: 0;
}

.search .detail {
    padding-bottom: 1rem;
    margin-top: 8rem;
    min-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    direction: ltr;
}

.search .detail .row {
    direction: rtl;
    align-content: start;
}

.search .detail .row .stanza {
    margin: 1rem;
    text-align: center;
    font-size: 1.6rem;
}

.search .detail .row span:hover {
    color: var(--secondary);
    transition: var(--transition);
    cursor: pointer;
}

.search .detail a {
    font-size: 1.8rem;
    color: var(--black);
    text-decoration: none;
}

.search .detail a:hover {
    color: var(--secondary);
    transition: var(--transition);
}

.search .detail::-webkit-scrollbar {
    width: 1rem;
}

.search .detail::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 5rem;
}

.search .detail h1 {
    color: var(--primary);
    font-size: 3rem;
    margin: 2rem;
    text-align: center;
    position: sticky;
    top: 0;
    background: var(--white);
    padding: 1rem;
}

/* !Search End */

/* !About Start */
.about {
    min-height: 100vh;
    padding-top: 10rem;
}

.about h1 {
    width: 100%;
    text-align: center;
    font-size: 4rem;
}

.about p {
    text-align: justify;
    margin-top: 2rem;
}

/* !About End */